<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Информация о классе
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <GroupName :group="groupInfo"></GroupName>
            <b-tabs pills card id="">
<!--              <b-tab>
                <template v-slot:title>
                  Ученики
                </template>
                <Students :group-id="groupId" ref="studentList"></Students>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  Предметы
                </template>
                <GroupSubjects :group-id="groupId" ref="subjects"></GroupSubjects>
              </b-tab>    
              <b-tab>
                <template v-slot:title>
                  Расписание
                </template>
                <TimeTableForGroup :group-id="groupId" ref="timetable"></TimeTableForGroup>
              </b-tab> -->
              <b-tab @click="reloadAbsenceLog">
                <template v-slot:title>
                  Журнал отсутсвующих
                </template>
                <AbsenceLog :group-id="groupId+''" ref="absenceLog"></AbsenceLog>
              </b-tab>
              <b-tab @click="reloadJournalNotes">
                <template v-slot:title>
                  Замечания по ведению журнала
                </template>
                <JournalNotes :group-id="groupId+''" :mode="'group'" :groupSubjectId="''" ref="journalNotes"></JournalNotes>
              </b-tab>      
              <b-tab @click="reloadGroupSocialPassport">
                <template v-slot:title>
                  Социальный паспорт класса
                </template>
                <GroupSocialPassport :group-id="groupId+''" ref="groupSocialPassport"></GroupSocialPassport>
              </b-tab>  
              <b-tab @click="reloadInformationAboutBooks">
                <template v-slot:title>
                  {{ $t("informationAboutBooks.title") }}
                </template>
                <InformationAboutBooks :group-id="groupId+''" ref="informationAboutBooks"></InformationAboutBooks>
              </b-tab>    
              <b-tab @click="reloadSocialUsefulWork">
                <template v-slot:title>
                  {{ $t("socialUsefulWork.title") }}
                </template>
                <SocialUsefulWork :group-id="groupId+''" ref="socialUsefulWork"></SocialUsefulWork>
              </b-tab>
              <b-tab @click="reloadInformationAboutClassesInClub">
                <template v-slot:title>
                  {{ $t("informationAboutClassesInClub.title") }}
                </template>
                <InformationAboutClassesInClub :group-id="groupId+''" ref="informationAboutClassesInClub"></InformationAboutClassesInClub>
              </b-tab> 
              <b-tab @click="reloadInformationAboutPhysicalActivityIndicators">
                <template v-slot:title>
                  {{ $t("informationAboutPhysicalActivityIndicators.title") }}
                </template>
                <InformationAboutPhysicalActivityIndicators :group-id="groupId+''" ref="informationAboutPhysicalActivityIndicators"></InformationAboutPhysicalActivityIndicators>
              </b-tab>   
              <b-tab @click="reloadInformationAboutHeath">
                <template v-slot:title>
                  {{ $t("informationAboutHeath.title") }}
                </template>
                <InformationAboutHeath :group-id="groupId+''" ref="informationAboutHeath"></InformationAboutHeath>
              </b-tab>
              <b-tab @click="reloadGroupSummaryReportOfStudentsProgressReport">
                <template v-slot:title>
                  {{ $t("summaryReportOfStudentsProgress.title") }}
                </template>
                <GroupSummaryReportOfStudentsProgressReport :group-id="groupId+''" ref="groupSummaryReportOfStudentsProgressReport"></GroupSummaryReportOfStudentsProgressReport>
              </b-tab>    
              <b-tab @click="reloadGroupFinalMarksCountReport">
                <template v-slot:title>
                  {{ $t("groupFinalMarksCountReport.title") }}
                </template>
                <GroupFinalMarksCountReport :group-id="groupId+''" ref="groupFinalMarksCountReport"></GroupFinalMarksCountReport>
              </b-tab>
              <b-tab @click="reloadGroupInformationAboutStudentMovementReport">
                <template v-slot:title>
                  {{ $t("groupInformationAboutStudentMovementReport.title") }}
                </template>
                <GroupInformationAboutStudentMovementReport :group-id="groupId+''" ref="groupInformationAboutStudentMovementReport"></GroupInformationAboutStudentMovementReport>
              </b-tab>       
              <b-tab @click="reloadGroupInformationAboutStudentLeavingReport">
                <template v-slot:title>
                  {{ $t("informationAboutStudentLeaving.title") }}
                </template>
                <GroupInformationAboutStudentLeavingReport :group-id="groupId+''" ref="groupInformationAboutStudentLeavingReport"></GroupInformationAboutStudentLeavingReport>
              </b-tab>
              <b-tab @click="reloadGroupStudentAbsenceNumberOfDaysReport">
                <template v-slot:title>
                  {{ $t("studentAbsenceNumberOfDays.title") }}
                </template>
                <GroupStudentAbsenceNumberOfDaysReport :group-id="groupId+''" ref="groupStudentAbsenceNumberOfDaysReport"></GroupStudentAbsenceNumberOfDaysReport>
              </b-tab>
            </b-tabs>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import GroupName from "./partials/GroupName.vue";
/*import Students from "./partials/Students.vue";
import GroupSubjects from "./partials/GroupSubjects.vue";
import TimeTableForGroup from "./partials/TimeTableForGroup.vue";*/
import AbsenceLog from "./partials/AbsenceLog.vue";
import JournalNotes from "./partials/JournalNotes.vue";
import GroupSocialPassport from "./partials/GroupSocialPassport.vue";
import InformationAboutBooks from "./partials/InformationAboutBooks.vue";
import SocialUsefulWork from "./partials/SocialUsefulWork.vue";
import InformationAboutClassesInClub from "./partials/InformationAboutClassesInClub.vue";
import InformationAboutPhysicalActivityIndicators from "./partials/InformationAboutPhysicalActivityIndicators.vue";
import InformationAboutHeath from "./partials/InformationAboutHeath.vue";
import GroupSummaryReportOfStudentsProgressReport from "./partials/GroupSummaryReportOfStudentsProgressReport.vue";
import GroupFinalMarksCountReport from "./partials/GroupFinalMarksCountReport.vue";
import GroupInformationAboutStudentMovementReport from "./partials/GroupInformationAboutStudentMovementReport.vue";
import GroupInformationAboutStudentLeavingReport from "./partials/GroupInformationAboutStudentLeavingReport.vue";
import GroupStudentAbsenceNumberOfDaysReport from "./partials/GroupStudentAbsenceNumberOfDaysReport.vue";


export default {
  name: "groupInfo",
  components: {
    KTPortlet,
    GroupName,
/*    Students,
    GroupSubjects,
    TimeTableForGroup,*/
    AbsenceLog,
    JournalNotes,
    GroupSocialPassport,
    InformationAboutBooks,
    SocialUsefulWork,
    InformationAboutClassesInClub,
    InformationAboutPhysicalActivityIndicators,
    InformationAboutHeath,
    GroupSummaryReportOfStudentsProgressReport,
    GroupFinalMarksCountReport,
    GroupInformationAboutStudentMovementReport,
    GroupInformationAboutStudentLeavingReport,
    GroupStudentAbsenceNumberOfDaysReport,
  },
  data() {
    return {
      groupId:this.$route.params.groupId,
      groupInfo: {},
    };
  },
  mounted() {
    ApiService.querySecured("studentGroups/find", {
      params: {id: this.$route.params.groupId}
    }).then(({data}) => {
      this.groupInfo = data;
    }).catch(({response}) => {
      console.log(response);
    });
  },
  methods: {
    reloadAbsenceLog(){
      this.$refs['absenceLog'].reloadData();
    },
    reloadJournalNotes(){
      this.$refs['journalNotes'].reloadData();
    },
    reloadGroupSocialPassport(){
      this.$refs['groupSocialPassport'].reloadData();
    },
    reloadInformationAboutBooks(){
      this.$refs['informationAboutBooks'].reloadData();
    },
    reloadSocialUsefulWork(){
      this.$refs['socialUsefulWork'].reloadData();
    },
    reloadInformationAboutClassesInClub(){
      this.$refs['informationAboutClassesInClub'].reloadData();
    },
    reloadInformationAboutPhysicalActivityIndicators(){
      this.$refs['informationAboutPhysicalActivityIndicators'].reloadData();
    },
    reloadInformationAboutHeath(){
      this.$refs['informationAboutHeath'].reloadData();
    },
    reloadGroupSummaryReportOfStudentsProgressReport(){
      this.$refs['groupSummaryReportOfStudentsProgressReport'].reloadData();
    },
    reloadGroupFinalMarksCountReport(){
      this.$refs['groupFinalMarksCountReport'].reloadData();
    },
    reloadGroupInformationAboutStudentMovementReport(){
      this.$refs['groupInformationAboutStudentMovementReport'].reloadData();
    },
    reloadGroupInformationAboutStudentLeavingReport(){
      this.$refs['groupInformationAboutStudentLeavingReport'].reloadData();
    },
    reloadGroupStudentAbsenceNumberOfDaysReport(){
      this.$refs['groupStudentAbsenceNumberOfDaysReport'].reloadData();
    }
  }
};
</script>
